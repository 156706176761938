.blurb {
    font-family: Helvetica, sans-serif;
    color: black;
    text-align: center;
}
.heading {
    font-size: 22px;
}
.subheading {
    font-size: 18px;
}
.button,
.button:hover,
.button:focus,
.button:active,
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled):active:focus {
    background: transparent;
    border-width: 0px;
    border-color: transparent;
    color: black;
    box-shadow: none;
}
@media screen and (max-width: 800px) {
    .heading {
        font-size: 18px;
    }
    .subheading {
        font-size: 16px;
    }
}
