.project {
    background: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    margin: 1rem;
    position: relative;
    width: 400px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 15px;
}
.text-center {
    padding: 5px;
}
.float-right {
    padding: 10px;
}
.subheading {
    padding-top: 10px;
}
.footer-card {
    min-width: 100%;
    /* push footer to bottom */
    margin-top: auto;
    /* ensure width includes padding */
    /* position: absolute; */
}
