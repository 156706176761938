.aboutme {
    background: white;
    padding: 20px;
}
.aboutme-pic {
    width: "35rem";
}
@media screen and(max-width:800px) {
    .aboutme-pic {
        width: "25rem";
    }
}
